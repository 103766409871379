
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonLabel, IonItem, IonList } from '@ionic/vue';
import { pencil } from 'ionicons/icons';

export default  {
    name: 'Recipes',
    components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage },
    setup() {
        return {
            pencil
        }
    },
    data() {
        return {
            tools: [
                {
                    name: "hefekugel-Rechner",
                    label: "Hefekugelrechner"
                },
                {
                    name: "ausrollrechner",
                    label: "Ausrollmaß-Rechner"
                },
                {
                    name: "backzeitrechner",
                    label: "Backzeit-Rechner"
                },
                {
                    name: "backzeitrechner",
                    label: "Umrechnungstabellen"
                },
                {
                    name: "gutebaecker",
                    label: "Gute Bäcker"
                },
                {
                    name: "gutemuehlen",
                    label: "Gute Mühlen"
                },
                {
                    name: "backsupport",
                    label: "Back Support"
                },
                {
                    name: "forum",
                    label: "Forum"
                },
                {
                    name: "datenschutz",
                    label: "Datenschutz"
                }
            ]
        }
    },
    computed: {

    },
}
